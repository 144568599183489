import LoaderSplash from "layout/LoaderSplash";
import YuvaLoader from "pages/Forum/components/Loader/YuvaLoader";
import Swal from "sweetalert2";

const popupConfig = {
  html: "",
  imageHeight: 100,
  timer: undefined,
  timerProgressBar: true,
  customClass: {
    container: "custom-swal-container",
  },
  willOpen: () => {
    // Inline styles for responsiveness
    const swalPopup = document.querySelector(".swal2-popup");
    if (swalPopup) {
      swalPopup.style.width = "auto";
      swalPopup.style.maxWidth = "60vw";
      swalPopup.style.boxSizing = "border-box";
    }
  },
};

export const Popup = (attr, title, description, timer) => {
  switch (attr) {
    case "loading":
      return <LoaderSplash />;
    case "loading-yuva":
      return <YuvaLoader />;
    case "success":
      Swal.fire({
        ...popupConfig,
        title: title || "Success",
        html: description,
        imageUrl: "https://cdn-icons-png.flaticon.com/512/7518/7518748.png",
        timer: timer,
      });
      break;
    case "warning":
      Swal.fire({
        ...popupConfig,
        title: title || "Warning",
        html: description,
        imageUrl: "https://cdn-icons-png.flaticon.com/512/8213/8213126.png",
        timer: timer,
      });
      break;
    case "error":
      Swal.fire({
        ...popupConfig,
        title: title || "Something Went Wrong. Check your Network Connection",
        html: description,
        imageUrl: "https://cdn-icons-png.flaticon.com/512/2581/2581801.png",
        timer: timer,
      });
      break;
    case "timedout":
      Swal.fire({
        ...popupConfig,
        title: title || "Session Timed Out",
        html: description,
        imageUrl: "https://cdn-icons-png.flaticon.com/512/771/771495.png",
        timer: timer,
      });
      break;
    default:
      return <LoaderSplash show={false} />;
  }
};

export const pop2 = {
  loading: () => <LoaderSplash />,
  loadingYuva: () => <YuvaLoader />,
  success: ({ title, description, timer }) => {
    Swal.fire({
      ...popupConfig,
      title: title || "Success",
      html: description,
      imageUrl: "https://cdn-icons-png.flaticon.com/512/7518/7518748.png",
      timer: timer,
    });
  },
  warning: ({ title, description, timer }) => {
    Swal.fire({
      ...popupConfig,
      title: title || "Warning",
      html: description,
      imageUrl: "https://cdn-icons-png.flaticon.com/512/8213/8213126.png",
      timer: timer,
    });
  },
  error: ({ title, description, timer }) => {
    Swal.fire({
      ...popupConfig,
      title: title || "Something Went Wrong. Check your Network Connection",
      html: description,
      imageUrl: "https://cdn-icons-png.flaticon.com/512/2581/2581801.png",
      timer: timer,
    });
  },
  timedout: ({ title, description, timer }) => {
    Swal.fire({
      ...popupConfig,
      title: title || "Session Timed Out",
      html: description,
      imageUrl: "https://cdn-icons-png.flaticon.com/512/771/771495.png",
      timer: timer,
    });
  },
  clear: () => {
    Swal.close();
  },
  confirm: () => <LoaderSplash show={false} />,
};