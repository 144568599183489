import React from 'react';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Select from "react-select"
import ViewListIcon from '@mui/icons-material/ViewList';
import GridOnIcon from '@mui/icons-material/GridOn';
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor = theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const SimpleBreadCrumb = ({ page, }) => {
  // const route = useLocation().pathname;
  // const routeArr = route.split('/');
  // const Navigate = useNavigate();
  // let backroute = '/';
  return (
    // <div className="bg-primary py-2 py-lg-4 ">
      <>
        {/* <nav aria-label="breadcrumb">
          <ol className="breadcrumb bg-transparent p-0 text-lowercase">
            {routeArr.map((rout, index) => {
              return index === 0 ? (
                <li key={index} className="breadcrumb-item">
                  <Link className="text-white" to="/">
                    <small className="text-capitalize">
                      Home
                    </small>
                  </Link>
                </li>
              ) : (
                <li key={index} className="breadcrumb-item">
                  <Link className="text-white" to={`/ ${rout}`}>
                    <small className="text-capitalize">
                      {rout}
                    </small>
                  </Link>
                </li>
              );
            })}
          </ol>
        </nav> */}
        {/* <h4 className="text-white text-capitalize fs-3">{page}</h4> */}
          <div className="">
                <span className="my-teacher-student-content">{page === "student" ? "Manage Students" : "Manage Teachers"}</span>
          </div>
      </>
    // </div>
  );
};

export default SimpleBreadCrumb;
const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "143px",
    height: "40px",
    padding: "3px",
    border: "none",
    background: "#F9ECFF",
    color: 'black'
    // boxShadow:'none'
  }),
};

 {/* <div className="d-flex justify-content-between">
              <div className="d-flex justify-content-between align-items-center ">
                <div className="d-flex justify-content-evenly align-items-center" style={{ background: "#F9ECFF", borderRadius: "7px", width: "81px", height: "40px" }}>
                  <div className='' style={{ width: "40px" }}><ViewListIcon sx={{ color: "#DBCFFF", width: "28px", height: "28px" }} /></div>
                  <div className='' style={{ width: "40px" }}><GridOnIcon sx={{ color: "#3D00FF", width: "28px", height: "28px" }} /></div>
                </div>
              </div>
            </div> */}

             {/* <div className="col-4">
              <div className="d-flex align-items-center justify-content-between">
                
                <div style={{ fontFamily: 'Lexend', wordWrap: 'break-word' }}><span className="fs-17-50px fw-400">Class</span></div>
                <div>
                <Select
                    styles={customStyles}
                  />
                </div>

                <div className="d-flex align-items-center"  >
                <div className="d-flex justify-content-evenly align-items-center" style={{ background: "#F9ECFF", borderRadius: "7px", width: "81px", height: "40px" }}>
                  <div><ViewListIcon sx={{ color: "#DBCFFF", width: "28px", height: "28px" }}/></div>
                  <div><GridOnIcon sx={{ color: "#3D00FF", width: "28px", height: "28px" }} /></div>
                  </div>
                </div>
                
              </div>
              </div> */}

               {/* <div className="d-flex justify-content-between align-items-center"> */}
              {/* <div className="">
              <div><span  className="my-teacher-student-content">{page === "student" ? "Manage Students" : "Manage Teachers"}</span></div>
              </div> */}