export const UNEP_Countries = [
    { name: "Afghanistan", value: "Afghanistan" },
    { name: "Albania", value: "Albania" },
    { name: "Algeria", value: "Algeria" },
    { name: "Andorra", value: "Andorra" },
    { name: "Angola", value: "Angola" },
    { name: "Antigua and Barbuda", value: "Antigua and Barbuda" },
    { name: "Argentina", value: "Argentina" },
    { name: "Armenia", value: "Armenia" },
    { name: "Australia", value: "Australia" },
    { name: "Austria", value: "Austria" },
    { name: "Azerbaijan", value: "Azerbaijan" },
    { name: "Bahamas", value: "Bahamas" },
    { name: "Bahrain", value: "Bahrain" },
    { name: "Bangladesh", value: "Bangladesh" },
    { name: "Barbados", value: "Barbados" },
    { name: "Belarus", value: "Belarus" },
    { name: "Belgium", value: "Belgium" },
    { name: "Belize", value: "Belize" },
    { name: "Benin", value: "Benin" },
    { name: "Bhutan", value: "Bhutan" },
    { name: "Bolivia (Plurinational State of)", value: "Bolivia (Plurinational State of)" },
    { name: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
    { name: "Botswana", value: "Botswana" },
    { name: "Brazil", value: "Brazil" },
    { name: "Brunei Darussalam", value: "Brunei Darussalam" },
    { name: "Bulgaria", value: "Bulgaria" },
    { name: "Burkina Faso", value: "Burkina Faso" },
    { name: "Burundi", value: "Burundi" },
    { name: "Cabo Verde", value: "Cabo Verde" },
    { name: "Cambodia", value: "Cambodia" },
    { name: "Cameroon", value: "Cameroon" },
    { name: "Canada", value: "Canada" },
    { name: "Central African Republic", value: "Central African Republic" },
    { name: "Chad", value: "Chad" },
    { name: "Chile", value: "Chile" },
    { name: "China", value: "China" },
    { name: "Colombia", value: "Colombia" },
    { name: "Comoros", value: "Comoros" },
    { name: "Congo", value: "Congo" },
    { name: "Cook Islands", value: "Cook Islands" },
    { name: "Costa Rica", value: "Costa Rica" },
    { name: "Côte d'Ivoire", value: "Côte d'Ivoire" },
    { name: "Croatia", value: "Croatia" },
    { name: "Cuba", value: "Cuba" },
    { name: "Cyprus", value: "Cyprus" },
    { name: "Czechia", value: "Czechia" },
    { name: "Democratic People's Republic of Korea", value: "Democratic People's Republic of Korea" },
    { name: "Democratic Republic of the Congo", value: "Democratic Republic of the Congo" },
    { name: "Denmark", value: "Denmark" },
    { name: "Djibouti", value: "Djibouti" },
    { name: "Dominica", value: "Dominica" },
    { name: "Dominican Republic", value: "Dominican Republic" },
    { name: "Ecuador", value: "Ecuador" },
    { name: "Egypt", value: "Egypt" },
    { name: "El Salvador", value: "El Salvador" },
    { name: "Equatorial Guinea", value: "Equatorial Guinea" },
    { name: "Eritrea", value: "Eritrea" },
    { name: "Estonia", value: "Estonia" },
    { name: "Eswatini", value: "Eswatini" },
    { name: "Ethiopia", value: "Ethiopia" },
    { name: "European Union", value: "European Union" },
    { name: "Fiji", value: "Fiji" },
    { name: "Finland", value: "Finland" },
    { name: "France", value: "France" },
    { name: "Gabon", value: "Gabon" },
    { name: "Gambia", value: "Gambia" },
    { name: "Georgia", value: "Georgia" },
    { name: "Germany", value: "Germany" },
    { name: "Ghana", value: "Ghana" },
    { name: "Greece", value: "Greece" },
    { name: "Grenada", value: "Grenada" },
    { name: "Guatemala", value: "Guatemala" },
    { name: "Guinea", value: "Guinea" },
    { name: "Guinea-Bissau", value: "Guinea-Bissau" },
    { name: "Guyana", value: "Guyana" },
    { name: "Haiti", value: "Haiti" },
    { name: "Honduras", value: "Honduras" },
    { name: "Hungary", value: "Hungary" },
    { name: "Iceland", value: "Iceland" },
    { name: "India", value: "India" },
    { name: "Indonesia", value: "Indonesia" },
    { name: "Iran (Islamic Republic of)", value: "Iran (Islamic Republic of)" },
    { name: "Iraq", value: "Iraq" },
    { name: "Ireland", value: "Ireland" },
    { name: "Israel", value: "Israel" },
    { name: "Italy", value: "Italy" },
    { name: "Jamaica", value: "Jamaica" },
    { name: "Japan", value: "Japan" },
    { name: "Jordan", value: "Jordan" },
    { name: "Kazakhstan", value: "Kazakhstan" },
    { name: "Kenya", value: "Kenya" },
    { name: "Kiribati", value: "Kiribati" },
    { name: "Kuwait", value: "Kuwait" },
    { name: "Kyrgyzstan", value: "Kyrgyzstan" },
    { name: "Lao People's Democratic Republic", value: "Lao People's Democratic Republic" },
    { name: "Latvia", value: "Latvia" },
    { name: "Lebanon", value: "Lebanon" },
    { name: "Lesotho", value: "Lesotho" },
    { name: "Liberia", value: "Liberia" },
    { name: "Libya", value: "Libya" },
    { name: "Liechtenstein", value: "Liechtenstein" },
    { name: "Lithuania", value: "Lithuania" },
    { name: "Luxembourg", value: "Luxembourg" },
    { name: "Madagascar", value: "Madagascar" },
    { name: "Malawi", value: "Malawi" },
    { name: "Malaysia", value: "Malaysia" },
    { name: "Maldives", value: "Maldives" },
    { name: "Mali", value: "Mali" },
    { name: "Malta", value: "Malta" },
    { name: "Marshall Islands", value: "Marshall Islands" },
    { name: "Mauritania", value: "Mauritania" },
    { name: "Mauritius", value: "Mauritius" },
    { name: "Mexico", value: "Mexico" },
    { name: "Micronesia (Federated States of)", value: "Micronesia (Federated States of)" },
    { name: "Monaco", value: "Monaco" },
    { name: "Mongolia", value: "Mongolia" },
    { name: "Montenegro", value: "Montenegro" },
    { name: "Morocco", value: "Morocco" },
    { name: "Mozambique", value: "Mozambique" },
    { name: "Myanmar", value: "Myanmar" },
    { name: "Namibia", value: "Namibia" },
    { name: "Nauru", value: "Nauru" },
    { name: "Nepal", value: "Nepal" },
    { name: "Netherlands", value: "Netherlands" },
    { name: "New Zealand", value: "New Zealand" },
    { name: "Nicaragua", value: "Nicaragua" },
    { name: "Niger", value: "Niger" },
    { name: "Nigeria", value: "Nigeria" },
    { name: "Niue", value: "Niue" },
    { name: "North Macedonia", value: "North Macedonia" },
    { name: "Norway", value: "Norway" },
    { name: "Oman", value: "Oman" },
    { name: "Pakistan", value: "Pakistan" },
    { name: "Palau", value: "Palau" },
    { name: "Panama", value: "Panama" },
    { name: "Papua New Guinea", value: "Papua New Guinea" },
    { name: "Paraguay", value: "Paraguay" },
    { name: "Peru", value: "Peru" },
    { name: "Philippines", value: "Philippines" },
    { name: "Poland", value: "Poland" },
    { name: "Portugal", value: "Portugal" },
    { name: "Qatar", value: "Qatar" },
    { name: "Republic of Korea", value: "Republic of Korea" },
    { name: "Republic of Moldova", value: "Republic of Moldova" },
    { name: "Romania", value: "Romania" },
    { name: "Russian Federation", value: "Russian Federation" },
    { name: "Rwanda", value: "Rwanda" },
    { name: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
    { name: "Saint Lucia", value: "Saint Lucia" },
    { name: "Saint Vincent and the Grenadines", value: "Saint Vincent and the Grenadines" },
    { name: "Samoa", value: "Samoa" },
    { name: "San Marino", value: "San Marino" },
    { name: "Sao Tome and Principe", value: "Sao Tome and Principe" },
    { name: "Saudi Arabia", value: "Saudi Arabia" },
    { name: "Senegal", value: "Senegal" },
    { name: "Serbia", value: "Serbia" },
    { name: "Seychelles", value: "Seychelles" },
    { name: "Sierra Leone", value: "Sierra Leone" },
    { name: "Singapore", value: "Singapore" },
    { name: "Slovakia", value: "Slovakia" },
    { name: "Slovenia", value: "Slovenia" },
    { name: "Solomon Islands", value: "Solomon Islands" },
    { name: "Somalia", value: "Somalia" },
    { name: "South Africa", value: "South Africa" },
    { name: "South Sudan", value: "South Sudan" },
    { name: "Spain", value: "Spain" },
    { name: "Sri Lanka", value: "Sri Lanka" },
    { name: "State of Palestine", value: "State of Palestine" },
    { name: "Sudan", value: "Sudan" },
    { name: "Suriname", value: "Suriname" },
    { name: "Sweden", value: "Sweden" },
    { name: "Switzerland", value: "Switzerland" },
    { name: "Syrian Arab Republic", value: "Syrian Arab Republic" },
    { name: "Tajikistan", value: "Tajikistan" },
    { name: "Thailand", value: "Thailand" },
    { name: "Timor-Leste", value: "Timor-Leste" },
    { name: "Togo", value: "Togo" },
    { name: "Tonga", value: "Tonga" },
    { name: "Trinidad and Tobago", value: "Trinidad and Tobago" },
    { name: "Tunisia", value: "Tunisia" },
    { name: "Turkey", value: "Turkey" },
    { name: "Turkmenistan", value: "Turkmenistan" },
    { name: "Tuvalu", value: "Tuvalu" },
    { name: "Uganda", value: "Uganda" },
    { name: "Ukraine", value: "Ukraine" },
    { name: "United Arab Emirates", value: "United Arab Emirates" },
    { name: "United Kingdom of Great Britain and Northern Ireland", value: "United Kingdom of Great Britain and Northern Ireland" },
    { name: "United Republic of Tanzania", value: "United Republic of Tanzania" },
    { name: "United States of America", value: "United States of America" },
    { name: "Uruguay", value: "Uruguay" },
    { name: "Uzbekistan", value: "Uzbekistan" },
    { name: "Vanuatu", value: "Vanuatu" },
    { name: "Venezuela, Bolivarian Republic of", value: "Venezuela, Bolivarian Republic of" },
    { name: "Viet Nam", value: "Viet Nam" },
    { name: "Yemen", value: "Yemen" },
    { name: "Zambia", value: "Zambia" },
    { name: "Zimbabwe", value: "Zimbabwe" }
]
export const COP28_countries = [
    { name: "Australia", value: "Australia" },
    { name: "Austria", value: "Austria" },
    { name: "Latvia", value: "Latvia" },
    { name: "Belgium", value: "Belgium" },
    { name: "Bulgaria", value: "Bulgaria" },
    { name: "Canada", value: "Canada" },
    { name: "Croatia", value: "Croatia" },
    { name: "Cyprus", value: "Cyprus" },
    { name: "Czech Republic", value: "Czech Republic" },
    { name: "Denmark", value: "Denmark" },
    { name: "Estonia", value: "Estonia" },
    { name: "EU", value: "EU" },
    { name: "Finland", value: "Finland" },
    { name: "France", value: "France" },
    { name: "Germany", value: "Germany" },
    { name: "Greece", value: "Greece" },
    { name: "Hungary", value: "Hungary" },
    { name: "Iceland", value: "Iceland" },
    { name: "Ireland", value: "Ireland" },
    { name: "Italy", value: "Italy" },
    { name: "Japan", value: "Japan" },
    { name: "Latvia", value: "Latvia" },
    { name: "Liechtenstein", value: "Liechtenstein" },
    { name: "Lithuania", value: "Lithuania" },
    { name: "Luxembourg", value: "Luxembourg" },
    { name: "Malta", value: "Malta" },
    { name: "Monaco", value: "Monaco" },
    { name: "Netherlands", value: "Netherlands" },
    { name: "New Zealand", value: "New Zealand" },
    { name: "Norway", value: "Norway" },
    { name: "Poland", value: "Poland" },
    { name: "Portugal", value: "Portugal" },
    { name: "Romania", value: "Romania" },
    { name: "Russian Federation", value: "Russian Federation" },
    { name: "Slovakia", value: "Slovakia" },
    { name: "Slovenia", value: "Slovenia" },
    { name: "Spain", value: "Spain" },
    { name: "Sweden", value: "Sweden" },
    { name: "Switzerland", value: "Switzerland" },
    { name: "Turkey", value: "Turkey" },
    { name: "Ukraine", value: "Ukraine" },
    { name: "United Kingdom", value: "United Kingdom" },
    { name: "United States of America", value: "United States of America" }
];

export const UNDP_Countries = [
  { name: 'Afghanistan', value: 'Afghanistan' },
  { name: 'Albania', value: 'Albania' },
  { name: 'Algeria', value: 'Algeria' },
  { name: 'Andorra', value: 'Andorra' },
  { name: 'Angola', value: 'Angola' },
  { name: 'Argentina', value: 'Argentina' },
  { name: 'Armenia', value: 'Armenia' },
  { name: 'Australia', value: 'Australia' },
  { name: 'Austria', value: 'Austria' },
  { name: 'Azerbaijan', value: 'Azerbaijan' },
  { name: 'Bahrain', value: 'Bahrain' },
  { name: 'Bangladesh', value: 'Bangladesh' },
  { name: 'Belarus', value: 'Belarus' },
  { name: 'Belgium', value: 'Belgium' },
  { name: 'Benin', value: 'Benin' },
  { name: 'Bhutan', value: 'Bhutan' },
  { name: 'Bolivia', value: 'Bolivia' },
  { name: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
  { name: 'Botswana', value: 'Botswana' },
  { name: 'Brazil', value: 'Brazil' },
  { name: 'Brunei', value: 'Brunei' },
  { name: 'Bulgaria', value: 'Bulgaria' },
  { name: 'Burkina Faso', value: 'Burkina Faso' },
  { name: 'Burundi', value: 'Burundi' },
  { name: 'Cambodia', value: 'Cambodia' },
  { name: 'Cameroon', value: 'Cameroon' },
  { name: 'Canada', value: 'Canada' },
  {
    name: 'Central African Republic',
    value: 'Central African Republic'
  },
  { name: 'Chad', value: 'Chad' },
  { name: 'Chile', value: 'Chile' },
  { name: 'China', value: 'China' },
  { name: 'Colombia', value: 'Colombia' },
  { name: 'Comoros', value: 'Comoros' },
  {
    name: 'Democratic Republic of the Congo',
    value: 'Democratic Republic of the Congo'
  },
  { name: 'Republic of the Congo', value: 'Republic of the Congo' },
  { name: 'Costa Rica', value: 'Costa Rica' },
  { name: 'Croatia', value: 'Croatia' },
  { name: 'Cuba', value: 'Cuba' },
  { name: 'Cyprus', value: 'Cyprus' },
  { name: 'Czechia', value: 'Czechia' },
  { name: 'Denmark', value: 'Denmark' },
  { name: 'Djibouti', value: 'Djibouti' },
  { name: 'Dominica', value: 'Dominica' },
  { name: 'Dominican Republic', value: 'Dominican Republic' },
  {
    name: 'East Timor (Timor-Leste)',
    value: 'East Timor (Timor-Leste)'
  },
  { name: 'Ecuador', value: 'Ecuador' },
  { name: 'Egypt', value: 'Egypt' },
  { name: 'El Salvador', value: 'El Salvador' },
  { name: 'Equatorial Guinea', value: 'Equatorial Guinea' },
  { name: 'Eritrea', value: 'Eritrea' },
  { name: 'Estonia', value: 'Estonia' },
  { name: 'Eswatini', value: 'Eswatini' },
  { name: 'Ethiopia', value: 'Ethiopia' },
  { name: 'Fiji', value: 'Fiji' },
  { name: 'Finland', value: 'Finland' },
  { name: 'France', value: 'France' },
  { name: 'Gabon', value: 'Gabon' },
  { name: 'Gambia', value: 'Gambia' },
  { name: 'Georgia', value: 'Georgia' },
  { name: 'Germany', value: 'Germany' },
  { name: 'Ghana', value: 'Ghana' },
  { name: 'Greece', value: 'Greece' },
  { name: 'Grenada', value: 'Grenada' },
  { name: 'Guatemala', value: 'Guatemala' },
  { name: 'Guinea', value: 'Guinea' },
  { name: 'Guinea-Bissau', value: 'Guinea-Bissau' },
  { name: 'Guyana', value: 'Guyana' },
  { name: 'Haiti', value: 'Haiti' },
  { name: 'Honduras', value: 'Honduras' },
  { name: 'Hungary', value: 'Hungary' },
  { name: 'Iceland', value: 'Iceland' },
  { name: 'India', value: 'India' },
  { name: 'Indonesia', value: 'Indonesia' },
  { name: 'Iran', value: 'Iran' },
  { name: 'Iraq', value: 'Iraq' },
  { name: 'Ireland', value: 'Ireland' },
  { name: 'Israel', value: 'Israel' },
  { name: 'Italy', value: 'Italy' },
  { name: 'Ivory Coast', value: 'Ivory Coast' },
  { name: 'Jamaica', value: 'Jamaica' },
  { name: 'Japan', value: 'Japan' },
  { name: 'Jordan', value: 'Jordan' },
  { name: 'Kazakhstan', value: 'Kazakhstan' },
  { name: 'Kenya', value: 'Kenya' },
  { name: 'Kiribati', value: 'Kiribati' },
  { name: 'Korea, North', value: 'Korea, North' },
  { name: 'Korea, South', value: 'Korea, South' },
  { name: 'Kosovo', value: 'Kosovo' },
  { name: 'Kuwait', value: 'Kuwait' },
  { name: 'Kyrgyzstan', value: 'Kyrgyzstan' },
  { name: 'Laos', value: 'Laos' },
  { name: 'Latvia', value: 'Latvia' },
  { name: 'Lebanon', value: 'Lebanon' },
  { name: 'Lesotho', value: 'Lesotho' },
  { name: 'Liberia', value: 'Liberia' },
  { name: 'Libya', value: 'Libya' },
  { name: 'Liechtenstein', value: 'Liechtenstein' },
  { name: 'Lithuania', value: 'Lithuania' },
  { name: 'Luxembourg', value: 'Luxembourg' },
  { name: 'Malaysia', value: 'Malaysia' },
  { name: 'Maldives', value: 'Maldives' },
  { name: 'Malta', value: 'Malta' },
  { name: 'Marshall Islands', value: 'Marshall Islands' },
  { name: 'Mauritania', value: 'Mauritania' },
  { name: 'Mauritius', value: 'Mauritius' },
  { name: 'Mexico', value: 'Mexico' },
  { name: 'Monaco', value: 'Monaco' },
  { name: 'Mongolia', value: 'Mongolia' },
  { name: 'Montenegro', value: 'Montenegro' },
  { name: 'Morocco', value: 'Morocco' },
  { name: 'Mozambique', value: 'Mozambique' },
  { name: 'Myanmar', value: 'Myanmar' },
  { name: 'Namibia', value: 'Namibia' },
  { name: 'Nauru', value: 'Nauru' },
  { name: 'Nepal', value: 'Nepal' },
  { name: 'Netherlands', value: 'Netherlands' },
  { name: 'New Zealand', value: 'New Zealand' },
  { name: 'Nicaragua', value: 'Nicaragua' },
  { name: 'Niger', value: 'Niger' },
  { name: 'Nigeria', value: 'Nigeria' },
  { name: 'North Macedonia', value: 'North Macedonia' },
  { name: 'Norway', value: 'Norway' },
  { name: 'Oman', value: 'Oman' },
  { name: 'Pakistan', value: 'Pakistan' },
  { name: 'Palau', value: 'Palau' },
  { name: 'Palestine', value: 'Palestine' },
  { name: 'Panama', value: 'Panama' },
  { name: 'Papua New Guinea', value: 'Papua New Guinea' },
  { name: 'Paraguay', value: 'Paraguay' },
  { name: 'Peru', value: 'Peru' },
  { name: 'Philippines', value: 'Philippines' },
  { name: 'Poland', value: 'Poland' },
  { name: 'Portugal', value: 'Portugal' },
  { name: 'Qatar', value: 'Qatar' },
  { name: 'Romania', value: 'Romania' },
  { name: 'Russia', value: 'Russia' },
  { name: 'Rwanda', value: 'Rwanda' },
  { name: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis' },
  { name: 'Saint Lucia', value: 'Saint Lucia' },
  {
    name: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines'
  },
  { name: 'Samoa', value: 'Samoa' },
  { name: 'San Marino', value: 'San Marino' },
  { name: 'Sao Tome and Principe', value: 'Sao Tome and Principe' },
  { name: 'Saudi Arabia', value: 'Saudi Arabia' },
  { name: 'Senegal', value: 'Senegal' },
  { name: 'Serbia', value: 'Serbia' },
  { name: 'Seychelles', value: 'Seychelles' },
  { name: 'Sierra Leone', value: 'Sierra Leone' },
  { name: 'Singapore', value: 'Singapore' },
  { name: 'Slovakia', value: 'Slovakia' },
  { name: 'Slovenia', value: 'Slovenia' },
  { name: 'Solomon Islands', value: 'Solomon Islands' },
  { name: 'Somalia', value: 'Somalia' },
  { name: 'South Africa', value: 'South Africa' },
  { name: 'South Sudan', value: 'South Sudan' },
  { name: 'Spain', value: 'Spain' },
  { name: 'Sri Lanka', value: 'Sri Lanka' },
  { name: 'Sudan', value: 'Sudan' },
  { name: 'Suriname', value: 'Suriname' },
  { name: 'Sweden', value: 'Sweden' },
  { name: 'Switzerland', value: 'Switzerland' },
  { name: 'Syria', value: 'Syria' },
  { name: 'Taiwan', value: 'Taiwan' },
  { name: 'Tajikistan', value: 'Tajikistan' },
  { name: 'Tanzania', value: 'Tanzania' },
  { name: 'Thailand', value: 'Thailand' },
  { name: 'Togo', value: 'Togo' },
  { name: 'Tonga', value: 'Tonga' },
  { name: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
  { name: 'Tunisia', value: 'Tunisia' },
  { name: 'Turkey', value: 'Turkey' },
  { name: 'Turkmenistan', value: 'Turkmenistan' },
  { name: 'Tuvalu', value: 'Tuvalu' },
  { name: 'Uganda', value: 'Uganda' },
  { name: 'Ukraine', value: 'Ukraine' },
  { name: 'United Arab Emirates', value: 'United Arab Emirates' },
  { name: 'United Kingdom', value: 'United Kingdom' },
  { name: 'United States', value: 'United States' },
  { name: 'Uruguay', value: 'Uruguay' },
  { name: 'Uzbekistan', value: 'Uzbekistan' },
  { name: 'Vanuatu', value: 'Vanuatu' },
  { name: 'Vatican City', value: 'Vatican City' },
  { name: 'Venezuela', value: 'Venezuela' },
  { name: 'Vietnam', value: 'Vietnam' },
  { name: 'Yemen', value: 'Yemen' },
  { name: 'Zambia', value: 'Zambia' },
  { name: 'Zimbabwe', value: 'Zimbabwe' }
]

export const G20_Countries = [
    { name: "Argentina", value: "Argentina" },
    { name: "Australia", value: "Australia" },
    { name: "Brazil", value: "Brazil" },
    { name: "Canada", value: "Canada" },
    { name: "China", value: "China" },
    { name: "France", value: "France" },
    { name: "Germany", value: "Germany" },
    { name: "India", value: "India" },
    { name: "Indonesia", value: "Indonesia" },
    { name: "Italy", value: "Italy" },
    { name: "Japan", value: "Japan" },
    { name: "the Republic of Korea", value: "the Republic of Korea" },
    { name: "Mexico", value: "Mexico" },
    { name: "Russia", value: "Russia" },
    { name: "Saudi Arabia", value: "Saudi Arabia" },
    { name: "South Africa", value: "South Africa" },
    { name: "Turkey", value: "Turkey" },
    { name: "the United Kingdom", value: "the United Kingdom" },
    { name: "the United States", value: "the United States" },
    { name: "the European Union", value: "the European Union" }
  ];
  
export const AIPPM_Role =[
  { name: 'Narendra Modi', value: 'Narendra Modi' },
  { name: 'Amit Shah', value: 'Amit Shah' },
  { name: 'Rajnath Singh', value: 'Rajnath Singh' },
  { name: 'Nirmala Sitharaman', value: 'Nirmala Sitharaman' },
  { name: 'Rahul Gandhi', value: 'Rahul Gandhi' },
  { name: 'Sonia Gandhi', value: 'Sonia Gandhi' },
  { name: 'Smriti Irani', value: 'Smriti Irani' },
  { name: 'Amarinder Singh', value: 'Amarinder Singh' },
  { name: 'B L Santhosh', value: 'B L Santhosh' },
  { name: 'Arvind Kejriwal', value: 'Arvind Kejriwal' },
  { name: 'Gaurav Gogoi', value: 'Gaurav Gogoi' },
  { name: 'Pinarayi Vijayan', value: 'Pinarayi Vijayan' },
  { name: 'Mamata Banerjee', value: 'Mamata Banerjee' },
  { name: 'Shashi Tharoor', value: 'Shashi Tharoor' },
  { name: 'Subrahmanyam Jaishankar', value: 'Subrahmanyam Jaishankar' },
  { name: 'Nitish Kumar', value: 'Nitish Kumar' },
  { name: 'Uddhav Thackeray', value: 'Uddhav Thackeray' },
  { name: 'Nitin Gadkari', value: 'Nitin Gadkari' },
  { name: 'Narendra Singh Tomar', value: 'Narendra Singh Tomar' },
  { name: 'Ravi Shankar Prasad', value: 'Ravi Shankar Prasad' },
  { name: 'Pragya Singh Thakur', value: 'Pragya Singh Thakur' },
  { name: 'Sachin Pilot', value: 'Sachin Pilot' },
  { name: 'Farooq Abdullah', value: 'Farooq Abdullah' },
  { name: 'Omar Abdullah', value: 'Omar Abdullah' },
  { name: 'P. Chidambaram', value: 'P. Chidambaram' },
  { name: 'Mulayam Singh Yadav', value: 'Mulayam Singh Yadav' },
  { name: 'J P Nadda', value: 'J P Nadda' },
  { name: 'Piyush Goyal', value: 'Piyush Goyal' },
  { name: 'Prakash Javadekar', value: 'Prakash Javadekar' },
  { name: 'Asaduddin Owaisi', value: 'Asaduddin Owaisi' },
  { name: 'Hema Malini', value: 'Hema Malini' },
  { name: 'Mallikarjun Khadge', value: 'Mallikarjun Khadge' },
  { name: 'Jaya Bachchan', value: 'Jaya Bachchan' },
  { name: 'Varun Gandhi', value: 'Varun Gandhi' },
  { name: 'Anurag Thakur', value: 'Anurag Thakur' },
  { name: 'Tejasvi Surya', value: 'Tejasvi Surya' },
  { name: 'Jairam Ramesh', value: 'Jairam Ramesh' },
  { name: 'Hardeep Singh Puri', value: 'Hardeep Singh Puri' },
  { name: 'Surpriya Sule', value: 'Surpriya Sule' },
  { name: 'Sanjay Singh', value: 'Sanjay Singh' },
  { name: 'Karti Chidambaram', value: 'Karti Chidambaram' },
  { name: 'Rajeev Chandrasekhar', value: 'Rajeev Chandrasekhar' },
  { name: 'Adhir Ranjan Chowdhury', value: 'Adhir Ranjan Chowdhury' },
  { name: 'Dharmendra Pradhan', value: 'Dharmendra Pradhan' },
  { name: 'Lalu Prasad Yadav', value: 'Lalu Prasad Yadav' },
  { name: 'Nitish Kumar', value: 'Nitish Kumar' },
  { name: 'MK Stalin', value: 'MK Stalin' },
  { name: 'Naveen Patnaik', value: 'Naveen Patnaik' },
  { name: 'Hemant Biswa Sharma', value: 'Hemant Biswa Sharma' },
  { name: 'Akhilesh Yadav', value: 'Akhilesh Yadav' }
 ];

export const G20_roles = [
    { value: "Finance Track", role: "Finance Minister" },
    { value: "Sherpa Track", role: "Sherpa" },
    { value: "Foreign Minister Track", role: "Foreign Ministers" },
    { value: "Leaders Track", role: "Head of State" }
  ];
 