import { ClubContext } from "pages/TimelineClubs/TimelineClub";
import React, { useContext, useEffect} from "react";
import { Card } from "react-bootstrap";
import AllActivity from "./AllActivity";

export const RecentClubQuestion = () => {
  const {
    allLikedPost,
    fetchAllLikedPostUser,
    allUserComment,
    fetchAllUserComment,
  } = useContext(ClubContext);

  useEffect(() => {
    fetchAllLikedPostUser();
    fetchAllUserComment();
  }, []);

  const addElipsis = (str, limit) =>{
    return str.length > limit ? str.substring(0, limit) + "..." : str;
}


  return (
    <div>
        <div className="mb-4">
  <Card className="rounded-3">
    <Card.Body>
          <div className="mb-1">
            <h4 className="fs-5 fw-bold">Recent Activity</h4>
            <hr />
          </div>
      {allUserComment?.length > 0 ? (
        <>
          {/* {allUserComment?.slice(0, 3)?.map((ele) => {
            return (
              <div key={ele.id}>
                <div className="mb-4">
                  <div>
                    <div>
                      <h6>{ele?.commentType}</h6>
                    </div>

                    <div className="row">
                      <div className="col-lg-8">
                        <span style={{ fontSize: "14px" }}>
                          {addElipsis(ele?.content, 30)}
                        </span>
                      </div>
                      <div className="col-lg-4 d-flex justify-content-end">
                        <span style={{ fontSize: "13px" }}>
                          {moment(ele?.createdAt).fromNow()}
                        </span>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            );
          })} */}
          {allUserComment?.slice(0, 6)?.map((item)=>(
          <AllActivity data={item} />
          ))}
        </>
      ) : (
        <div className="text-center">
          <span className="fs-6">No activity yet</span>
        </div>
      )}
    </Card.Body>
  </Card>
</div>

    </div>
  );
};
