import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, Grid, Pagination, TextField, Typography, useMediaQuery } from '@mui/material';
import { UserProfileCard } from './components/UserProfileCard';
import NotFoundGif from 'layout/NotFoundGif';
import SpreadsheetView from './components/SpreadsheetView';
import BulkUploadModal from './components/BulkUploadModal';
import { useNavigate, useParams } from 'react-router-dom';
import { FilePresentTwoTone } from '@mui/icons-material';
import moment from 'moment';
import { Modal } from "react-bootstrap";
import { useGlobalContext } from 'global/context';
import { apiJson, apiJsonAuth } from 'api';
import { Popup } from 'layout/Popup';
import YuvaLoader from 'pages/Forum/components/Loader/YuvaLoader';

const InstituteRegisteredUser = () => {

  const { role } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const [searchQuery, setSearchQuery] = useState("");
  const [correctData, setCorrectData] = useState([]);
  const [incorrectData, setIncorrectData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectUser, setSelectUser] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
  const { userData } = useGlobalContext();
  const { token } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await apiJson.get(`api/v2/institute/getMultipleTeacherStudent/${userData?.id}`);

      const getAllTeachStud = response?.data?.getAllTeachStud || [[], []];
      const [correctData, incorrectData] = getAllTeachStud;

      let fieldsToExtract = [
        "id",
        "first_name",
        "last_name",
        "father_name",
        "contact",
        "email",
        "instituteId",
        "dob",
        "role"
      ];

      if (role === "student") {
        fieldsToExtract = [...fieldsToExtract, "stream", "class"];
      }

      const formatData = (data) =>
        data
          .filter(user => user.role === role && user.instituteId === userData?.id)
          .map(user => {
            const filteredUser = {};
            fieldsToExtract.forEach(field => {
              if (user[field] !== undefined) {
                filteredUser[field] = field === 'dob'
                  ? moment(user[field]).format('DD-MM-YYYY')
                  : user[field];
              }
            });
            return filteredUser;
          });

      const filteredCorrectData = formatData(correctData);
      const filteredIncorrectData = formatData(incorrectData);

      setCorrectData(filteredCorrectData);
      setIncorrectData(filteredIncorrectData);
      setFilteredData(filteredCorrectData);
      setIsLoading(false);
    } catch (error) {
      const errorMessage = error.response?.data?.msg || 'An error occurred while updating data.';
      Popup("error", errorMessage);
      setIsLoading(false);
      setCorrectData([]);
      setIncorrectData([]);
      setFilteredData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [role]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filteredData]);

  useEffect(() => {
    if (currentPage > totalPages && totalPages > 0) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages]);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);

    const lowerCaseQuery = query.toLowerCase();

    const filtered = correctData.filter(user => {
      const firstName = user.first_name ? user.first_name.toString().toLowerCase() : '';
      const lastName = user.last_name ? user.last_name.toString().toLowerCase() : '';
      const fullName = `${firstName} ${lastName}`.trim();
      const id = user.id ? user.id.toString().toLowerCase() : '';

      return fullName.includes(lowerCaseQuery) || id.includes(lowerCaseQuery);
    });

    setFilteredData(filtered);
  };

  const handleNaviageProfile = () => {
    navigate(`/profile/user/${selectUser?.id}`);
  };

  const handleShowProfileModal = (id) => {
    const user = filteredData.find((user) => user?.id === id);
    setSelectUser(user);
    setShowProfileModal(true);
  };

  const handleCloseProfileModal = () => {
    setShowProfileModal(false);
  };

  const handleUpdateUser = async (userId, updatedData) => {

    try {
      const response = await apiJsonAuth.put(
        `api/v2/institute/studentDetailsCorrection/${userData?.id}`,
        { updatedData },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { msg } = response.data;
      Popup("success", msg);

      fetchData();
    } catch (error) {
      // check if we have that user or not in our filteredData if not than let them through otherwise throw error
      const user = filteredData.find((user) => user?.id === userId);
      if (!user) {
        const errorMessage = error.response?.data?.msg || 'An error occurred while updating data.';
        Popup("error", errorMessage);
      }
    }

  };

  const handleBulkUploadSuccess = () => {
    setBulkUploadModalOpen(false);
    fetchData();
  };

  const handleBulkUploadError = () => {
    setBulkUploadModalOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
          padding: "1rem"
        }}
      >
        {isLoading && <YuvaLoader show={isLoading} />}

        {/* First Main Box */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'space-between' },
            gap: '1rem',
            flexWrap: 'wrap',
            padding: "2rem"
          }}
        >
          {/* Heading of the page */}
          <Box
            sx={{
              textAlign: { xs: 'center', md: 'left' },
              width: { xs: '100%', md: 'auto' },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: { md: '1.75rem', lg: '3rem' },
                fontWeight: 600,
              }}
            >
              {role === 'student' ? 'Manage Students' : 'Manage Teachers'}
            </Typography>
          </Box>

          {/* Upload CSV button */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '150px',
            }}
          >
            <div className="d-flex align-items-center justify-content-center flex-wrap mt-3 mt-md-0">
              <button
                style={{
                  background: "white",
                  height: "25px",
                  padding: "0.5rem 1rem",
                  fontSize: '1rem',
                }}
                type="button"
                className="btn border-success text-success btn-outline-dark rounded-3"
                onClick={() => setBulkUploadModalOpen(true)}
              >
                <FilePresentTwoTone />
                Upload&nbsp;CSV
              </button>
            </div>
          </Box>
        </Box>

        {/* Second Main Box */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "0 1.5rem",
          }}
        >
          <Grid container spacing={2} sx={{ width: '100%', mb: 2 }}>
            {/* Search bar */}
            <Grid item xs={12} md={6}>
              <TextField
                label="Search by ID or Name"
                variant="standard"
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
                sx={{ width: '100%', maxWidth: '300px' }}
              />
            </Grid>

            {/* Count */}
            <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              {filteredData.length !== 0 && (
                <Typography variant="h6" sx={{ textAlign: 'center', fontSize: '1.25rem', fontWeight: 600 }}>
                  Total {role}s: {filteredData.length}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Box
            sx={{
              width: '100%',
              minHeight: '100vh',
              padding: '0 1rem',
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                justifyContent: {
                  xs: 'center',
                  sm: 'center',
                  md: 'flex-start',
                },
                alignItems: 'stretch',
              }}
            >
              {paginatedData.length > 0 ? (
                paginatedData.map((user, i) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                    <UserProfileCard
                      user={user}
                      handleShowModal={handleShowProfileModal}
                      sx={{ height: '100%' }}
                    />
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <NotFoundGif text={`No ${role} Has Registered Yet!`} />
                </Grid>
              )}
            </Grid>

            {/* Pagination Component */}
            {totalItems > itemsPerPage && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  defaultPage={1}
                  siblingCount={0}
                  boundaryCount={1}
                  onChange={handlePageChange}
                  color="primary"
                  size={isMobile ? 'small' : 'medium'}
                />
              </Box>
            )}

          </Box>

        </Box >

        {/* Third Main Box */}
        <Box
          sx={{
            marginY: "2rem",
            width: '100%',
            overflowX: 'auto',
            padding: '0.5rem',
          }
          }
        >
          {
            incorrectData.length > 0 && (
              <>
                <Box
                  sx={{
                    marginBottom: '1rem',
                    borderBottom: '2px solid #e0e0e0',
                    paddingBottom: '0.25rem',
                  }}
                >
                  <Typography
                    variant="h5"
                    component="h2"
                    color="textPrimary"
                    sx={{
                      fontWeight: 600,
                      textAlign: 'center',
                      fontSize: { xs: '1.5rem', sm: '1.75rem', lg: '2.5rem' },
                    }}
                  >
                    Incorrect Data Found
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    color="textSecondary"
                    sx={{
                      display: { xs: 'none', sm: 'block' },
                      textAlign: 'center',
                      marginTop: '0.25rem',
                    }}
                  >
                    Review and correct the information below. Users with incorrect data are listed, allowing you to update their details to meet our standards.
                  </Typography>
                </Box>

                <SpreadsheetView
                  incorrectData={incorrectData}
                  onUpdateUser={handleUpdateUser}
                />
              </>
            )
          }
        </Box >


        {/* Modal for Bulk Upload */}
        < Dialog
          open={bulkUploadModalOpen}
          maxWidth="lg"
          fullWidth >
          <BulkUploadModal
            role={role}
            open={bulkUploadModalOpen}
            onSuccess={handleBulkUploadSuccess}
            onError={handleBulkUploadError}
          />
        </Dialog >

        {/* Modal to view User profile - Done */}
        < Modal show={showProfileModal} onHide={handleCloseProfileModal} >
          <Modal.Header closeButton>
            <Modal.Title>View Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Box sx={{ paddingLeft: 3 }}>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid item xs={12} lg={3}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography variant="body1">
                    {selectUser?.first_name} {selectUser?.last_name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid item xs={12} lg={3}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                    Email
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography variant="body1">{selectUser?.email}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid item xs={12} lg={3}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                    D.O.B
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography variant="body1">{selectUser?.dob}</Typography>
                </Grid>
              </Grid>
              {selectUser?.address && (
                <Grid container spacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={12} lg={3}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                      Address
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Typography variant="body1">{selectUser?.address}</Typography>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid item xs={12} lg={3}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                    Contact
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography variant="body1">+91 {selectUser?.contact}</Typography>
                </Grid>
              </Grid>
              {selectUser?.father_name && (
                <Grid container spacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={12} lg={3}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                      Father Name
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Typography variant="body1">{selectUser?.father_name}</Typography>
                  </Grid>
                </Grid>
              )}
              {selectUser?.class && (
                <Grid container spacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={12} lg={3}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                      Class
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Typography variant="body1">{selectUser?.class}</Typography>
                  </Grid>
                </Grid>
              )}
              {selectUser?.score && (
                <Grid container spacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={12} lg={3}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                      Score
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Typography variant="body1">{selectUser?.score}</Typography>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseProfileModal}>
              Close
            </Button>
            <Button variant="primary" onClick={() => handleNaviageProfile(selectUser)}>
              Go to Profile
            </Button>
          </Modal.Footer>
        </Modal >
      </Box >
    </>
  );
};

export default InstituteRegisteredUser;