import * as React from "react";
import { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Avatar, Badge, IconButton } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
// import { useGlobalContext } from "global/context";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Tab, Tabs } from "react-bootstrap";
import { apiJsonAuth } from "api";
import { useGlobalContext } from "global/context";
import { Modal } from "react-bootstrap";

export const NotificationItem = ({ singlenotify, onClick, handleNotiyfyModalOpen }) => {
  const navigate = useNavigate();
  return (
    <MenuItem
      onClick={() => {
        navigate("/notification/" + singlenotify?.id);
      }}
      key={singlenotify?.id}
      className="text-wrap notification-item">
      {/* <div className="d-flex"> */}
        <div className="lh-1" onClick={()=>{ onClick()
           handleNotiyfyModalOpen(singlenotify?.id)}}>
          {/* <h6 className="mb-0 headline">{singlenotify?.heading}</h6> */}

          {/* <div className="text-secondary subline">
            <span style={{ color: "black", fontSize: "15px" }}>{singlenotify?.title}</span>
          </div> */}

          <div className="mt-1 d-flex">

            <div>

              {/* <div className="fw-light my-0 text-secondary subline">
                <Avatar src={singlenotify?.img} alt="" />
              </div> */}
            </div>

            <div>
              <div className="fw-light my-0 subline">
                <span>{singlenotify?.message}</span>
              </div>

              <div>
                <small className="text-dark date">{moment(singlenotify?.createdAt).calendar()}</small>
              </div>
            </div>

          </div>
        </div>
      {/* </div> */}
    </MenuItem>
  );
};

export function NotificationMenu() {
  const [notify, setNotify] = useState([]);
  const [notifyModalOpen, setNotifyModalOpen] = useState(false);
  const [discData, setDiscription] = useState({})
  const [filtterNotification, setFiltterNotification] = useState([]);
  const [filtter, setFiltter] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const {userData} = useGlobalContext()

  const getNotify = ()=>{
    try {
      apiJsonAuth.get(`api/v2/notification/getNotify/${userData?.instituteId}/${userData?.id}`)
      .then((res)=>{
        setNotify(res?.data?.getNotify)
        // console.log(res.data.getNotify);
      })
    } catch (error) {
      console.log(error);
    }
  }

  const updateReadStatus = (id)=>{
    try {
      apiJsonAuth.put(`api/v2/notification/updateStatus/${id}`)
      .then((res)=>{
        // getNotify();
        // console.log(res.data.updateStatus);
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    // getNotify()
  },[])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterNotify = (value) => {
    let filteredNotifications;
    if (value === "unread") {
      filteredNotifications = notify?.filter((ele) => ele?.status === "unread");
      setFiltterNotification(filteredNotifications);
    } else if (value === "read") {
      filteredNotifications = notify?.filter((ele) => ele?.status === "read");
    } else {
      filteredNotifications = notify;
    }
    setFiltter(filteredNotifications);
  }

  useEffect(() => {
    filterNotify("unread");
  },[notify]);

  const handleNotiyfyModalOpen = (id)=>{
    // console.log("id", id)
    handleClose()
    const notifyDiscription = notify?.find((ele) => ele?.id === id)
    setDiscription(notifyDiscription)
    // console.log("notifyDiscription", notifyDiscription)
    setNotifyModalOpen(true)
  }

  const handleNotiyfyModalClose = ()=>{
    setNotifyModalOpen(false)
  }

  // function fetchNotification() {
  //   apiJsonAuth
  //     .get("/public/notification", {
  //       unique_id: userData?.id,
  //       reference_id: userData?.instituteId,
  //       role: userData?.role,
  //     })
  //     .then((result) => {
  //       setNotify(result.data.notify);
  //     })
  //     .catch((error) => console.error(error));
  // }
  // useEffect(() => {
  //   if (userData) {
  //     // fetchNotification();
  //   }
  // }, []);
  return (
    <>
      {/* <IconButton size="sm" className="bg-white ms-1 p-0" aria-label="show 17 new notifications" color="inherit" id="basic-button" aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
        <Badge badgeContent={filtterNotification.length} color="error" variant="outlined" sx={{ fontSize: 5 }}>
          <NotificationsIcon color="warning" className="fs-4" />
        </Badge>
      </IconButton> */}
      <Menu
        disableScrollLock={true}
        className=" "
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 4,
          sx: {
            overflow: "hidden",
            mt: 1.5,
            scrollbarWidth: "1px",
            paddingTop: "0px",
            borderRadius: "20px",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        <div className="px-3 pt-2 pb-0 mb-0 border-0">
          <h6>Notifications</h6>
        </div>
        <div
          className="scroll-minibar p-0"
          style={{
            overflow: "auto",
            minHeight: "400px",
            maxWidth: "400px",
            minWidth: 400,
            width: "100%",
            maxHeight: "50vh",
          }}>
          <Tabs defaultActiveKey="unread" id="uncontrolled-tab-example" className="mb-2 notificationTab" onSelect={(e)=>filterNotify(e)}>
            <Tab eventKey="unread" title="Unread">
              <div>
                {notify?.length > 0 ? (
                  filtter?.map((singlenotify) => 
                  <>
                  <hr/>
                  <NotificationItem onClick={()=>updateReadStatus(singlenotify?.id)} handleNotiyfyModalOpen={handleNotiyfyModalOpen} singlenotify={singlenotify} />
                  </>
                  )
                ) : (
                  <div className="p-2 d-flex align-items-center justify-content-center flex-column">
                    <img src="/images/icons/notification.png" alt="notification" className="w-100" />
                    <h4>No Notifications Yet</h4>
                  </div>
                )}
              </div>
            </Tab>
            <Tab
              eventKey="read"
              title={
                <span>
                  <i className="bi bi-check-circle"></i> Read
                </span>
              }>
              <div>
                {notify?.length > 0 ? (
                  filtter?.map((singlenotify) => 
                  <>
                  <hr/>
                  <NotificationItem singlenotify={singlenotify} />
                  </>
                  )
                ) : (
                  <div className="p-2 d-flex align-items-center justify-content-center flex-column">
                    <img src="/images/icons/notification.png" alt="notification" className="w-100" />
                    <h4>No Notifications Yet</h4>
                  </div>
                )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </Menu>

      <Modal show={notifyModalOpen} onHide={handleNotiyfyModalClose} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
          <Modal.Title>MUN</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <div className='ps-3'>
                    <div className='row mt-1'>
                      <div className='d-flex col-12 col-lg-3'>
                        <div>
                          {/* <span className='fw-700'>Name</span> */}
                          <Avatar/>
                        </div>
                        </div>

                        <div className='col-12 col-lg-6 fw-400' style={{fontFamily: "Poppins"}}>
                          <div className='fw-400'>
                        <span className='text-capitalize'>{discData?.message}</span>
                          </div>
                          <div><span className="" style={{fontSize: "13px", color: ""}}>{moment(discData?.createdAt).calendar()}</span></div>
                        </div>
                        <div>
                      </div>
                    </div>

                    {/* Email */}
                    {/* <div className='row mt-1'>
                      <div className='d-flex col-12 col-lg-3'>
                        <div className='fw-700'>
                          <span>Email</span>
                        </div>
                        </div>

                        <div className='col-12 col-lg-6'>
                          <div className='fw-400'>
                        <span>{"selectUser?.email"}</span>
                          </div>
                        </div>
                        <div>
                      </div>
                    </div> */}
                    
                    {/* DOB */}
                    {/* <div className='row mt-1'>
                      <div className='d-flex col-12 col-lg-3'>
                        <div className='fw-700'>
                          <span>D.O.B</span>
                        </div>
                        </div>

                        <div className='col-12 col-lg-6'>
                          <div className='fw-400'>
                        <span>{moment(selectUser?.dob).format("DD-MM-YYYY")}</span>

                          </div>
                        </div>
                        <div>
                      </div>
                    </div> */}

                  {/* address */}
                 {/* {selectUser?.address && */}
                    {/* <div className='row  mt-1'>
                      <div className='d-flex col-12 col-lg-3'>
                        <div className='fw-700'>
                          <span>Address</span>
                        </div>
                        </div>

                        <div className='col-12 col-lg-6'>
                          <div className='fw-400'>
                        <span>{"selectUser?.address"}</span>
                          </div>
                        </div>
                        <div>
                      </div>
                    </div>                    */}
                 {/* } */}

                  {/* contact */}
                    {/* <div className='row  mt-1'>
                      <div className='d-flex col-12 col-lg-3'>
                        <div className='fw-700'>
                          <span>Contact</span>
                        </div>
                        </div>

                        <div className='col-12 col-lg-6'>
                          <div className='fw-400'>
                        <span>+91 {"selectUser?.contact"}</span>

                          </div>
                        </div>
                        <div>
                      </div>
                    </div> */}

                  {/* father name */}
                  {/* {selectUser?.father_name &&  */}
                    {/* <div className='row  mt-1'>
                      <div className='d-flex col-12 col-lg-3'>
                        <div className='fw-700'>
                          <span>Father Name</span>
                        </div>
                        </div>

                        <div className='col-12 col-lg-6'>
                        <span>{"selectUser?.father_name"}</span>
                        </div>
                        <div>
                      </div>
                    </div> */}
                  {/* } */}

                  {/* class */}
                  {/* {selectUser?.class && */}
                    {/* <div className='row  mt-1'>
                      <div className='d-flex col-12 col-lg-3'>
                        <div className='fw-700'>
                          <span>Class</span>
                        </div>
                        </div>

                        <div className='col-12 col-lg-6'>
                          <div className='fw-400'>
                        <span>{"selectUser?.class"}</span>

                          </div>
                        </div>
                        <div>
                      </div>
                    </div> */}
                  {/* } */}

                  {/* Role */}
                  {/* {selectUser?.role && */}
                    {/* <div className='row mt-1'>
                      <div className='d-flex col-12 col-lg-3'>
                        <di className="fw-700">
                          <span>Role</span>
                        </di>
                        </div>

                        <div className='col-12 col-lg-6'>
                          <div className='fw-400'>
                        <span className='text-capitalize'>{"selectUser?.role"}</span>
                          </div>
                        </div>
                        <div>
                      </div>
                    </div> */}
                  {/* } */}
                </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
