import React, { useEffect, useState } from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { apiJson } from 'api';
import { toast } from 'react-toastify';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar, Button, Divider } from '@mui/joy'

// Upoad csvfile
const UploadCSV = () => {
  const [instituteCbseData, setInstituteCbseData] = useState([])
  const uploadCsv = async (e)=>{
    const formData = new FormData();
    formData.append('file', e.target.files[0]);

    await apiJson.post(`/api/v2/admin/cbse_data_upoad`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res)=>{
        getAllCbseDetails()
        toast.dismiss()
        toast.success("Data added successfully")
        e.target.value = null
      })
      .catch((err)=>{
        toast.dismiss()
        toast.error("Interval server error")
      })
      }

      const getAllCbseDetails = ()=>{
        apiJson.get('api/v2/admin/getAllCbseDetail')
        .then((res)=>{
          setInstituteCbseData(res.data.cbseDetails)
        })
        .catch((err)=>{
          console.log(err);
        })
      }

      useEffect(()=>{
        getAllCbseDetails()
      },[])

      const deleteCbseDetail = (id)=>{
        apiJson.delete(`api/v2/admin/deleteCbseDetail/${id}`)
        .then((res)=>{
          getAllCbseDetails()
          toast.dismiss()
          toast.success("Deleted Successfully")
        })
        .catch((err)=>{
          toast.dismiss()
        toast.error("Interval server error")
        })
      }

    const data = [
      ['affliate_number',	'institution_name',	'address',	'state',	'district',	'level',	'email', 'pincode', 'stdcode', 'contact', 'link', 'cbgtype', 'pgtype']
    ];
  
    const downloadCSV = () => {
      const csv = convertArrayToCSV(data);
      const blob = new Blob([csv], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'cbse.csv';
      link.click();
    };

    const convertArrayToCSV = (array) => {
      let csv = '';
      array.forEach(item => {
        csv += Object.values(item).join(',') + '\n';
      });
      return csv;
    };

  return (
    <>
        <div className="container">
            <h2 className='text-center'>Upload CSV</h2>
            <p className="text-danger text-center m-0 p-0" >NOTE</p>
            <p className="text-danger text-center m-0 p-0">1. Only the provided format is allowed for file upload</p>
            <Button style={{position:"relative", top:"0.5rem", background: "#9700de"}} className="d-block m-auto w-25 px-2 py-1 text-center rounded-3 text-white" onClick={downloadCSV}>Download Template</Button>
            <div style={{border:"2px dashed silver", height:"250px", position:"relative", top:"1rem"}} className='w-75 rounded-5 m-auto d-flex justify-content-center align-items-center position-relative'>
                <CloudUploadIcon sx={{width:"200px", height:"200px", display:"block", margin:"auto", color:"silver"}}/>
                <input type="file" name="file" id="file" accept=".xlsx, .csv, .xls" className='position-absolute border border-danger top-0 left-0 w-100 h-100 rounded-5 opacity-0 cursor-pointer' onChange={(e)=>uploadCsv(e)} />
            </div>

            <div className="mt-5 mb-2">
                <TableContainer component={Paper} >
                    <Table aria-label="simple table">
                        <TableHead sx={{background:"#9700de"}}>
                            <TableRow>
                                <TableCell className='fw-semibold' align='center'  sx={{color:"#ffffff"}}>Affiliation No</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}>Institute Name</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}>Email</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}> Address</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}> Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ padding: "5px 10px" }}>
                            {instituteCbseData?.map((row) => (
                                <TableRow
                                    key={row?.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, marginTop: "5px" }}
                                >
                                <TableCell >{row?.affliate_number}</TableCell>
                                    <TableCell align="center"><span className=''>{row?.institution_name}</span></TableCell>
                                    <TableCell align="center">{row?.email}</TableCell>
                                    {/* <TableCell align='center'>{row?.contact}</TableCell>
                                    <TableCell align='center'>{row?.level}</TableCell> */}
                                    <TableCell align='center'>{row?.address}</TableCell>
                                    <TableCell align='center' onClick={()=>deleteCbseDetail(row?.id)}><Button>Delete</Button></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
       </div>
       <Divider className="my-3" />
        </div>
    </>
  )
}

export default UploadCSV