import { StudentProfileCardList } from "components/dashboard";
import React from "react";

export const InstituteRegisteredUserV2 = () => {
  return (
    <div>
      <StudentProfileCardList />
    </div>
  );
};
