import React from 'react';
import Avatar from "@mui/material/Avatar";

const getTruncatedName = (name) => {
  if (!name) return '';
  const nameParts = name.split(' ');
  return nameParts.slice(0, 2).join(' ');
};

export const UserProfileCard = ({ user, handleShowModal }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'auto',
        padding: '8px',
      }}
    >
      <div className="card px-6 py-2 border-0 shadow rounded-5" style={{ width: '173px', height: '260px' }}>
        <div className="card-body mx-auto d-flex px-0 py-0">
          <div className="h-100 w-100">
            <div className="d-flex justify-content-center">
              <div
                style={{
                  objectFit: 'cover',
                  height: '120px',
                  width: '120px',
                }}
              >
                <Avatar
                  style={{
                    height: "120px",
                    width: "120px",
                    borderRadius: '64.5px',
                  }}
                  alt={user.first_name}
                  src={user?.profile}
                />
              </div>
            </div>
            <div
              className="degination d-flex justify-content-center align-items-center"
              style={{ background: '#FFE7AA', position: 'relative', bottom: '8px' }}
            >
              <span className="font-family-Lexend font-size-11px d-block" style={{ color: '#7B4300' }}>
                {user?.role}
              </span>
            </div>
            <div className="height-48px-width-99px justify-content-center text-center mx-auto d-flex align-items-center mt-1">
              <p className="font-family-Lexend font-weight-600" style={{ fontSize: '16px' }}>
                {getTruncatedName(`${user?.first_name} ${user?.last_name}`)}
              </p>
            </div>
            <div onClick={() => handleShowModal(user?.id)} className="mt-1 mx-auto rounded text-center" style={{ background: '#c4afff', width: '100px', height: '27px', cursor: 'pointer' }}>
              <div><span style={{ color: '#5a0c99', fontSize: '12px', fontWeight: '600', fontFamily: 'Lexend' }}>View Profile</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}