import { Avatar } from '@mui/material'
import { useGlobalContext } from 'global/context'
import moment from 'moment'
import React from 'react'
import { useNavigate } from 'react-router'

const CommentPostActivity = ({data}) => {
    const {userData} = useGlobalContext()
    const navigate = useNavigate()
    const handleNavigateCommentedPost = (clubId,postId)=>{
    navigate(`/clubs/${clubId}/post/${postId}`)
    }
  return (
    <>
        <div>
             <div className="d-flex mt-2 cursor-pointer commentPost" onClick={()=>handleNavigateCommentedPost(data?.clubId,data?.postId)} key={data?.createdAt}>
                <div style={{ maxHeight: "45px", maxWidth: "45px" }}>
                   <Avatar src={data?.profile} alt={data.commentBy}  />
                </div>
                <div className="d-flex flex-column ms-2">
                    <div className='text-justify'>
                    <span className="font-14 fw-semibold text-justify">{data.userId === userData?.id ? "You" : data.commentBy}</span> <span className='font-14'> commented on</span> <span className='font-14 fw-semibold'>{data.postBy}'s.</span> <span className='font-14'>Post</span>
                    
                    </div>
                  <span className='font-14'>{moment(data?.createdAt).fromNow()}</span>
                </div>
            </div>
          </div>
    </>
  )
}

export default CommentPostActivity